.stepper-box {
    display: flex;
    gap: 5px;
    margin-top: 5px;
    flex-direction: column;
    border-bottom: 1px solid #f1efef;
    padding-bottom: 10px;

    .description{
        display: flex;
        gap: 5px;
        font-size: 11px;
        color: #1c74e9;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    .column{
        font-size: 11px;
        color: #81A2CF;
    }

    .bullet-v2 {
        font-size: 11px;
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        border-radius: 60px;
        display: flex;
        gap: 5px;
        height: 15px;
        align-items: center;
        background: #F1F1F1;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    .row {
        width: 20px;
        height: 3px;
        background-color: #eceef0;
    }

    .completed {
        border: 1px solid #eceef0;
        color: #11bf72;
    }

    .working {
        color: #68696b;
    }

    .locked {
        color: #d7dae2;
    }

    .active-bullet {
        border: 1px solid #eceef0;
        color: #1c74e9;
    }

    .full-stepper {
        max-width: 300px;
        overflow: hidden;
    }

    .inside-stepper {
        width: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pct {
        display: flex;
        width: 4%;
        background: #5551FF;
        height: 15px;
        border-radius: 10px;
        position: relative;
    }

    .icon-pct{
        width: 18px;
        height: 18px;
        border: solid 2px #5551FF;
        background-color: #EEF2FF;
        border-radius: 50px;
        position: absolute;
        right: 0px;
        top: -2px;
    }

}