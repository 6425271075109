.card {
    background-color: #fff;
    padding: 3px;
    border-radius: 10px;
    display: flex;
    min-height: 356px;
    -webkit-box-shadow: -1px 2px 23px -7px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: -1px 2px 23px -7px rgba(0, 0, 0, 0.28);
    box-shadow: -1px 2px 23px -7px rgba(0, 0, 0, 0.28);
    cursor: pointer;
}

.container-icon-top {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border: 2px solid #51555c;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;

    svg {
        width: 15px;
        height: 15px;
    }
}

.active-folder {
    opacity: 1;
}

.card-container {
    background: #F5F5F5;
    background: linear-gradient(0deg, #F5F5F5, #FFFFFF);
    display: flex;
    flex: 1;
    border-radius: 10px;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
}

.order-name {
    display: flex;
    gap: 10px;
    font-size: 16px;
    color: #51555c;
    align-items: center;
}

.container-icon {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 15px;
        height: 15px;
        color: #5551FF;
    }
}

.info-id {
    font-size: 13px;
    line-height: normal;
}

.info-order {
    display: flex;
    flex-direction: column;
}

.row-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1efef;
    padding-bottom: 10px;
}

.product-order {
    font-size: 14px;
    font-weight: bold;
    color: #5551FF;
}

.col-delivery-info {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.front {
    display: flex;
    font-size: 11px;
    flex-direction: column;
    gap: 5px;
    text-transform: uppercase;
    align-items: center;
    width: 50%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;

    img {
        object-fit: contain;
        object-fit: contain;
        width: 100%;
        height: 100%;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.2));
        object-position: bottom right;
    }
}

.back {
    display: flex;
    font-size: 11px;
    flex-direction: column;
    gap: 5px;
    text-transform: uppercase;
    align-items: center;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;

    img {
        object-fit: contain;
        object-fit: contain;
        width: 100%;
        height: 100%;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.2));
        object-position: top left;
    }
}

.row-images {
    position: relative;
    min-height: 190px;
    max-height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-bottom: 1px solid #F2F2F2;
    padding: 21px;
    box-sizing: border-box;
}

.row-delivery {
    border-top: 1px solid #f1efef;
    padding-top: 10px;
}

.title-delivery {
    display: flex;
    align-items: center;
    color: #51555c;
}

.infos-delivery {
    line-height: 17px;

    span {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
}

.info-delivery {
    font-size: 13px;
}

.row-delivery {
    border-top: 1px solid #FFF;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}

.row-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border-top: 1px solid #f1efef;
    padding-top: 10px;
}

.card-box {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}

.card-status-box{
    position: absolute;
    right: 20px;
    top: 30px;
}

@media (min-width: 700px) {
    .card-box {
        width: 50%;
    }
}

@media (min-width: 1000px) {
    .card-box {
        width: 33%;
    }
}

@media (min-width: 1400px) {
    .card-box {
        width: 25%;
    }
}

@media (min-width: 1800px) {
    .card-box {
        width: 20%;
    }
}