.full-container {
    width: 100%;
    height: 110vh;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0EFFF;
    background: linear-gradient(45deg, #F0EFFF, #FFFBFA);
    position: absolute;
    left: 0;
    top: 0;
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .left-container {
        padding-right: 30px;
        display: flex;
        max-width: 453px;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        min-width: 260px;
    }

    .right-container {
        display: flex;
        position: relative;
        flex: 1;
        min-width: 540px;
        max-width: 800px;
        flex-wrap: wrap;

        .container-box {
            max-width: 400px;
            box-sizing: border-box;
            width: calc(50% - 30px);
            margin: 15px;
            min-height: 194px;
            position: relative;
        }

        .container-one {
            background-image: url('../../../../../../assets/images/splash-screen/frame-1-no-shadow.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right bottom;
        }

        .container-action-one {
            background-size: contain;
            left: 190px;
            top: -45px;
            width: 22%;
            height: 88px;
            position: absolute;
            background-repeat: no-repeat;
            background-image: url('../../../../../../assets/images/splash-screen/action-1-no-shadow.png');
        }

        .container-action-two {
            background-size: contain;
            right: 95px;
            bottom: -40px;
            width: 22%;
            height: 60px;
            position: absolute;
            background-repeat: no-repeat;
            background-image: url('../../../../../../assets/images/splash-screen/action-2-no-shadow.png');
        }

        .container-two {
            background-image: url('../../../../../../assets/images/splash-screen/frame-2-no-shadow.png');
            background-size: contain;
            background-repeat: no-repeat;
        }

        .container-three {
            background-image: url('../../../../../../assets/images/splash-screen/frame-3-no-shadow.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right;
            background-size: contain;
            border-bottom: 40px solid transparent;
            background-clip: padding-box;
        }

        .container-four {
            background-image: url('../../../../../../assets/images/splash-screen/frame-4-no-shadow.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}



@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes float-side {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0px);
    }
}

.float-action {
    animation: float-site 5s ease-in-out infinite;
}

.float-animation {
    animation: float 5s ease-in-out infinite;
}

.float-delay-1 {
    animation-delay: 0.2s;
}

y .float-delay-2 {
    animation-delay: 0.9s;
}

.float-delay-3 {
    animation-delay: 1.3s;
}