
@import "components/x-grid";
@import "animations";

* {
	font-family: 'Segoe UI', sans-serif !important;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fallbackCell {
	background-color: #FFD994;
}

/* Define the scrollbar track */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: #eeeeee;
}

/* Define the scrollbar handle */
::-webkit-scrollbar-thumb {
	background-color: #c0c0c0;
	border-radius: 20px;
}

/* Define the scrollbar track on hover */
::-webkit-scrollbar-track:hover {

}

/* Define the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: #b0b0b0;
}

.shimmer-thumbnail{
	margin: 0 !important;
}

.pdf-full-height{
	.react-pdf__Document, .react-pdf__Document *{
		width: 100% !important;
		height: 100% !important;
	}
}

.react-pdf__Page{
	border-radius: inherit;
}