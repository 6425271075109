@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(81, 85, 255, 0.5);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(81, 85, 255, 0);
    }
  }
  

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border: none '!important';
}