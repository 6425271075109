.order-id {
    font-size: 11px;
    font-weight: bold;
}

.step-box {
    font-size: 11px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 60px;
    border: 2px solid #eceef0;
    display: flex;
    gap: 5px;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
    background: #F5F5F5;
    background: linear-gradient(0deg, #F5F5F5, #FFFFFF);
    min-width: 110px;
    height: 40px;
    cursor: pointer;

    .numb-val {
        width: 20px;
        height: 20px;
        background-color: #dfdfdf;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        color: #b1b1b1;
    }

    .val-description {
        color: #8a8a8a;
        flex: 1;
        justify-content: center;
        text-align: center;
    }
}

.percentage-box{
    display: flex;
    gap: 5px;
    align-items: center;
    .bullet{
        width: 5px;
        height: 5px;
        background: #7979E1;
        border-radius: 3px;
    }
}

.tag-status {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    border-radius: 30px;
    font-size: 11px;
}

.draft {
    background: #f0f0f0;
    color: grey;
}

.mailed {
    background: #cff0e7;
    color: #379f7f;
}

.production {
    background: #fff6d5;
    color: #eed074;
}