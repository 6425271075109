// Reset some default styles from funnel-graph-js

.label__title {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #5551FF !important;
    position: absolute;
    top: -35px;
}

.label__value {
    margin: 1rem 0 0 0;
    color: #333 !important;
    font-size: 40px !important;
    font-weight: bold;

    span {
        // Cards Word - Style
        position: absolute;
        font-size: 18px;
        top: 10px;
        margin-left: 10px;
        font-weight: 300;
    }
}

.svg-funnel-js__subLabel--title {
    color: #192249 !important;
}

// Hide Tooltip informations
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label:hover .label__segment-percentages {
    display: none !important;
}

.svg-funnel-js .svg-funnel-js__subLabels {
    display: none;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
    border-color: #eef2ff !important;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label {
    &:first-child {
       //  padding-left: 35px !important;
    }
}
