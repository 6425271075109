.container-to-hide{
    width: 460px;
    height: 100%;
    position: absolute;
    left: 115px;
    z-index: 14;
    overflow: hidden;
    top: 0px;
}

.card-editor-box{
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}